import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Video from "../components/video"

import tfm_cell from "../assets/images/Composite_mid_scalebar_pos88.png"

const LeftSidebar = props => (
  <Layout>
    <Helmet>
      <title>Mechanobiology | Live Cell Technologies Canada</title>
      <meta name="description" content="Mechanobiology" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Mechanobiology</h2>
          <p>Physics matters.</p>
        </header>

        <section id="content">
          <a className="image right">
            <img src={tfm_cell} alt="" />
          </a>
          <p>
            Our bodies are extraordinarily complex active machines; myriads of
            pumps, plumbing, filters, and scaffolds which are not only
            undergoing continuous repair, but adapting in response to mechanical
            forces. Force-sensing and mechanical adaptation can be seen in
            nearly every aspect of our physiology<sup>[1,2]</sup>; for example,
            vascular cells undergo profound morphological changes in response to
            blood flow shear stresses; these responses have implications in the
            physiological function of blood vessels, particularly in disorders
            such as in atherosclerosis and thrombosis<sup>[3,4]</sup>.
            Force-mediated changes in cell mechanics also are critical in
            pathological states; in kidney health, the filtration barrier
            appears to be regulated by mechanosensing, and genetic defects in
            this sensing lead to proteinuria and renal failure<sup>[5]</sup>. In
            cancer, a disease characterized by diverse biochemical changes,
            cells appear to universally soften and generate higher forces
            <sup>[9,10]</sup>, potentially allowing them to invade new tissue
            more readily. Additionally, metastatic cells’ mode of movement may
            switch from crawling to "blebbing", where they move ~20x faster
            through fibrous tissues, presenting a far more invasive and
            difficult cancer
            <sup>[6,7]</sup>.
          </p>
          <Video
            videoSrcURL="https://www.youtube.com/embed/TKkFIM9JYAY?rel=0"
            videoTitle="Migrating Epithelial Cells"
            width="960"
            height="480"
          />
          <p>
            As such, physical mechanics has now become widely accepted as
            critically regulating biology<sup>[8]</sup>. The internal mechanics
            of biology are largely set by a collection of intracellular
            biopolymers known as the cytoskeleton, the cellular scaffold
            essential for virtually every aspect of life, and its disruption is
            the root cause of many aforementioned diseases<sup>[9,10]</sup>.
            Similarly, the therapeutic mechanism of actions for several drugs
            directly or indirectly affect this cellular scaffold<sup>[9]</sup>,
            making it important to understand the changes they impose on the
            mechanical state of the cell.
          </p>
          <p>
            Quantitative morphological and mechanical characterization of cells
            can therefore be an important measure of their degree of deviation
            from a healthy state, or alternately of their responsiveness to a
            drug<sup>[9]</sup>. One such method is{" "}
            <a href="/innovation">Traction Force Microscopy (TFM)</a>, which
            quantifies the forces that cells exert on their surrounding
            environment<sup>[9]</sup>. These methods could be used as new
            metrics for cell response to drugs in development and would offer a
            unique perspective on physiology.
          </p>
          <h3>References</h3>
          <ol>
            <li>
              Vogel, V. & Sheetz, M. Local force and geometry sensing regulate
              cell functions. Nat Rev Mol Cell Biol 7, 265-275 (2006).
            </li>
            <li>
              Wang, N., Tytell, J. D. & Ingber, D. E. Mechanotransduction at a
              distance: mechanically coupling the extracellular matrix with the
              nucleus. Nat Rev Mol Cell Biol 10, 75-82, doi:nrm2594 [pii]
              10.1038/nrm2594 (2009).
            </li>
            <li>
              Davies, P. F., Spaan, J. A. & Krams, R. Shear stress biology of
              the endothelium. Ann Biomed Eng 33, 1714-1718 (2005).
            </li>
            <li>
              Hahn, C. & Schwartz, M. A. Mechanotransduction in vascular
              physiology and atherogenesis. Nat Rev Mol Cell Biol 10, 53-62,
              doi:nrm2596 [pii] 10.1038/nrm2596 (2009).
            </li>
            <li>
              Akilesh, S. et al. Arhgap24 inactivates Rac1 in mouse podocytes,
              and a mutant form is associated with familial focal segmental
              glomerulosclerosis. J Clin Invest 121, 4127-4137,
              doi:10.1172/JCI46458 (2011).
            </li>
            <li>
              Sanz-Moreno, V. et al. Rac activation and inactivation control
              plasticity of tumor cell movement. Cell 135, 510-523,
              doi:S0092-8674(08)01236-1 [pii] 10.1016/j.cell.2008.09.043 (2008).
            </li>
            <li>
              Saito, K., Ozawa, Y., Hibino, K. & Ohta, Y. FilGAP, a
              Rho/ROCK-regulated GAP for Rac controls tumor cell migration. Mol
              Biol Cell, doi:mbc.E12-04-0310 [pii] 10.1091/mbc.E12-04-0310
              (2012).
            </li>
            <li>
              Ehrlicher, A. J., Nakamura, F., Hartwig, J. H., Weitz, D. A. &
              Stossel, T. P. Mechanical strain in actin networks regulates
              FilGAP and integrin binding to filamin A. Nature (2011).
            </li>
            <li>
              Yoshie H, Koushki N, Kaviani R, Tabatabaei SM, Rajendran K, Dang
              Q, Husain A, Yao S, Li C, Sullivan J, Saint-Geniez M, Krishnan R*,
              Ehrlicher AJ*. Traction force screening enabled by compliant PDMS
              elastomers. Biophys J, 114(9): 2194-2199, 2018. * contributed
              equally
            </li>
            <li>
              Yoshie H, Koushki N, Molter C, Siegel PM, Krishnan R, Ehrlicher
              AJ. High Throughput Traction Force Microscopy Using PDMS Reveals
              Dose-Dependent Effects of Transforming Growth Factor-β on the
              Epithelial-to-Mesenchymal Transition. J Vis Exp. Jun 1;(148),
              2019.
            </li>
          </ol>
        </section>
      </div>
    </div>
  </Layout>
)

export default LeftSidebar
